// Name of the Main Company (used to filter the data)
// Can view data of all companies
// default: 'merios GmbH'
const main_company = "merios GmbH";

// Destination of the API Calls
// default: 'http://172.20.10.11:8080/'
const api_url = process.env.REACT_APP_REACTSERVER_API_URL || 'http://localhost:8000/api/v3/';

// Duration of the Cookies in hours
// default: 24
const cookieExpireTime = 24;

// Duration of the Login Token in Hours
// default: 24*7 (7 Days)
const loginTokenDuration = 24*7;

// Access Levels
// default: {}
const accessLevels = {
  0: "Angestellter",
  3: "Abteilungs Manager",
  4: "Firmen Manager",
  6: "Admin",
  9: "Root Admin",
};

// Required Access for Functions
// default: {}
const requiredAccessFunctions = {
};

// Required Access for Pages
// default: {"Home": 0,}
const requiredAccessPages = {
  Zeiterfassung: 0,
  Übersicht: 0,
  Administration: 6,
  "Firmen-Verwaltung": 9,
  "Urlaubs-Verwaltung": 0,
};

// Data Grid Page Sizes
// Default: [10, 25, 50, 100]
const gridPageSizes = [6, 10, 25, 50, 100];
const defaultPageSize = gridPageSizes[0];

// Show the Privacy Policy and Impressum
// Default: true
const showPrivacyPolicy = false;

const config = {
  main_company: main_company,
  api_url: api_url,
  loginTokenDuration: loginTokenDuration,
  cookieExpireTime: cookieExpireTime,
  accessConfig: {
    accessLevels: accessLevels,
    requiredAccessFunctions: requiredAccessFunctions,
    requiredAccessPages: requiredAccessPages,
  },
  gridConfig: {
    defaultPageSize: defaultPageSize,
    gridPageSizes: gridPageSizes,
  },
  showPrivacyPolicy: showPrivacyPolicy,
};

export default config;
