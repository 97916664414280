import CookieManager from "./cookie-manager";

var themes = ["light-mode", "dark-mode"];

var current_theme = themes[1];

var default_color = "#9cca2a";

var listner = [];

export function setThemeFromMemory() {
  var theme = CookieManager.getCookie("theme");
  if (theme) {
    current_theme = theme;
    setTheme(theme);
  } else {
    setTheme(current_theme);
  }
  adjustThemeToCompany();
}

export function setTheme(theme) {
  if (themes.includes(theme)) {
    document.body.classList.remove(current_theme);
    current_theme = theme;
    CookieManager.setCookie("theme", theme, 9999);
    document.body.classList.add(theme);
    adjustThemeToCompany();
    listner.forEach((callback) => {
      callback();
    });
  }
}

export function toggleTheme() {
  if (current_theme === themes[0]) {
    setTheme(themes[1]);
  } else {
    setTheme(themes[0]);
  }
  adjustThemeToCompany();
}

export function adjustThemeToCompany() {
  let color = CookieManager.getCookie("company-color");
  if (color) {
    adjustThemeToCompanyColor(color);
  }
}

export function adjustThemeToCompanyColor(color) {
  console.log("Adjusting theme to company color: " + color);
  if (color) {
    CookieManager.setCookie("company-color", color, 9999);
    let theme = document.querySelector(`.${current_theme}`);
    if (theme) {
      let lumen = getLumen(color);
      let hsl = hexToHSL(color);
      theme.style.setProperty("--base-color", hsl);
      theme.style.setProperty("--base-color-faint", alphaHSL(hsl, 0.1));

      if (lumen < 120) {
        theme.style.setProperty("--base-accent-text-color", "#FFF");
        theme.style.setProperty("--base-color-focus", lightenHSL(hsl, 10));
        theme.style.setProperty("--base-color-hover", lightenHSL(hsl, 10));
        theme.style.setProperty("--base-color-active", lightenHSL(hsl, 15));
      } else if (lumen >= 120) {
        theme.style.setProperty("--base-accent-text-color", "#000");
        theme.style.setProperty("--base-color-focus", darkenHSL(hsl, 10));
        theme.style.setProperty("--base-color-hover", darkenHSL(hsl, 10));
        theme.style.setProperty("--base-color-active", darkenHSL(hsl, 15));
      }
    }
  }
}

export function resetThemeToDefault() {
  adjustThemeToCompanyColor(default_color);
}

export function getCompanyColor() {
  let color = CookieManager.getCookie("company-color");
  if (color) {
    return color;
  } else {
    return default_color;
  }
}

export function getContrastColor(color) {
  let lumen = getLumen(color);
  if (lumen < 128) {
    return "#ffffff";
  } else {
    return "#000000";
  }
}

export function getTheme() {
  return current_theme;
}

export function getThemeStyle() {
  let theme = document.querySelector(`.${current_theme}`);
  if (theme) {
    let computedStyle = getComputedStyle(theme);
    let style = {
      color: computedStyle.getPropertyValue("--base-color"),
      text: computedStyle.getPropertyValue("--base-text-color"),
      accent: computedStyle.getPropertyValue("--base-accent-text-color"),
      text_weak: computedStyle.getPropertyValue("--base-text-color-weak"),
      background: computedStyle.getPropertyValue("--base-background-color"),
    }
    return style;
  }
}

export function attachThemeListener(callback) {
  listner.push(callback);
}

export function detachThemeListener(callback) {
  listner = listner.filter((listener) => listener !== callback);
}

function hexToHSL(hex) {
  // Split the hex color into RGB
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  var r = parseInt(result[1], 16);
  var g = parseInt(result[2], 16);
  var b = parseInt(result[3], 16);

  r /= 255;
  g /= 255;
  b /= 255;
  var max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  var h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    var d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) { // eslint-disable-line default-case
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  s = s * 100;
  s = Math.round(s);
  l = l * 100;
  l = Math.round(l);
  h = Math.round(360 * h);

  var colorInHSL = "hsl(" + h + ", " + s + "%, " + l + "%)";
  return colorInHSL;
}

function darkenHSL(color, amount) {
  var result = /^hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)$/i.exec(color);
  var h = parseInt(result[1]);
  var s = parseInt(result[2]);
  var l = parseInt(result[3]);

  l -= amount;
  if (l < 0) {
    l = 0;
  }
  return `hsl(${h}, ${s}%, ${l}%)`;
}

function lightenHSL(color, amount) {
  var result = /^hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)$/i.exec(color);
  var h = parseInt(result[1]);
  var s = parseInt(result[2]);
  var l = parseInt(result[3]);

  l += amount;
  if (l > 100) {
    l = 100;
  }
  return `hsl(${h}, ${s}%, ${l}%)`;
}

function alphaHSL(color, amount) {
  var result = /^hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)$/i.exec(color);
  var h = parseInt(result[1]);
  var s = parseInt(result[2]);
  var l = parseInt(result[3]);

  return `hsla(${h}, ${s}%, ${l}%, ${amount})`;
}

export function getLumen(color) {
  var c = color.substring(1); // strip #
  var rgb = parseInt(c, 16); // convert rrggbb to decimal
  var r = (rgb >> 16) & 0xff; // extract red
  var g = (rgb >> 8) & 0xff; // extract green
  var b = (rgb >> 0) & 0xff; // extract blue

  var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
  return luma;
}