import React, { useState, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
} from "devextreme-react/form";
import LoadIndicator from "devextreme-react/load-indicator";
import notify from "devextreme/ui/notify";

import { changePassword } from "../../../api/auth";
import {useAuth} from "../../../contexts/auth"

import "./ChangePasswordForm.scss";

export default function ChangePasswordForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ password: "", oldpassword: ""});
  const { recoveryCode } = useParams();
  const { signOut } = useAuth();

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { password, oldpassword } = formData.current;
      setLoading(true);

      const result = await changePassword(oldpassword, password);
      setLoading(false);

      if (result.isOk) {
        signOut();
      } else {
        notify(result.message, "error", 2000);
      }
    },
    [navigate, recoveryCode] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const confirmPassword = useCallback(
    ({ value }) => value === formData.current.password,
    []
  );

  return (
    <form onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={"oldpassword"}
          editorType={"dxTextBox"}
          editorOptions={oldPasswordEditorOptions}
        >
          <RequiredRule message="Bitte geben Sie ihr altes Passwort ein" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={"password"}
          editorType={"dxTextBox"}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Bitte geben Sie ein Passwort ein" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={"confirmedPassword"}
          editorType={"dxTextBox"}
          editorOptions={confirmedPasswordEditorOptions}
        >
          <RequiredRule message="Bitte wiederholen Sie ihr Passwort" />
          <CustomRule
            message={"Die Passwörter stimmen nicht überein"}
            validationCallback={confirmPassword}
          />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={"100%"}
            type={"default"}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {loading ? (
                <LoadIndicator width={"24px"} height={"24px"} visible={true} />
              ) : (
                "Passwort ändern"
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );
}

const oldPasswordEditorOptions = {
  stylingMode: "filled",
  placeholder: "Altes Passwort",
  mode: "password",
};
const passwordEditorOptions = {
  stylingMode: "filled",
  placeholder: "Neues Passwort",
  mode: "password",
};
const confirmedPasswordEditorOptions = {
  stylingMode: "filled",
  placeholder: "Neues Passwort bestätigen",
  mode: "password",
};
