import notify from "devextreme/ui/notify";
import CookieManager from "../utils/cookie-manager";
import config from "../app-config";

/**
 * Default error handling function.
 *
 * Logs the error, the url and params that were sent.
 *
 * Also displays a small error message for the user.
 *
 *
 * @param {Error} error the error that occured
 * @param {String} url the url that was requested
 * @param {Object} params the params that were sent if any
 */
const defaultErrorHandling = (error, url, params) => {
  console.log(error);
  console.log(url, params);
  notify(`Fehler bei der Kommunikation mit dem Server ${url}`, "error", 1000);
};

export async function getCSRFToken(forceUpdate = false) {
  if (CookieManager.getCookie("csrftoken") && !forceUpdate) {
    return CookieManager.getCookie("csrftoken");
  }
  let response = await fetch(config.api_url + "csrf/", {
    method: "GET",
    mode: "cors",
    credentials: "include",
  })
  let data = await response.json();
  CookieManager.setCookie("csrftoken", data.csrfToken);
  return CookieManager.getCookie("csrftoken");
}
getCSRFToken();

let csrf_fail_counter = 0;
export async function handleCSRFErrors(response, url, params, method) {
  if (response.status === 403) {
    csrf_fail_counter++;
    console.log("CSRF Token invalid, fetching new one");
    CookieManager.deleteCookie("csrftoken");
    await getCSRFToken(true);
    console.log("Retrying request with new CSRF Token", CookieManager.getCookie("csrftoken"));
    if (csrf_fail_counter > 3) {
      console.error("Failed to get CSRF Token 3 times");
      return;
    }
    return await method(url, params);
  }
  csrf_fail_counter = 0;
  return response;
}

/**
 * Sends a GET request to the server.
 *
 * @param {String} url subpath to the server
 * @param {Function(Error, String, Object)} errorHandler Given an error, the url and the params, this function should handle the error
 * @returns {Promise<JSON>} Resolves to the JSON response from the server
 */
const get = async (
  url,
  searchparams = {},
  errorHandler = defaultErrorHandling
) => {
  return fetch(config.api_url + url + "?" + new URLSearchParams(searchparams), {
    method: "GET",
    mode: "cors", // Cross-Origin Resource Sharing (CORS)
    credentials: "omit",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + CookieManager.getCookie("token"), // Send the token in the header to authenticate the request
    },
  }).catch((error) => errorHandler(error, url, {}));
};

/**
 * Sends a POST request to the server
 *
 * @param {String} url subpath to the server
 * @param {Object} params Body of the request
 * @param {Function(Error, String, String)} errorHandler Given an error, the url and the params, this function should handle the error
 * @returns
 */
const post = async (url, params, errorHandler = defaultErrorHandling) => {
  return fetch(config.api_url + url, {
    method: "POST",
    mode: "cors", // Cross-Origin Resource Sharing (CORS)
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + CookieManager.getCookie("token"), // Send the token in the header to authenticate the request
      "X-CSRFToken": await getCSRFToken(), // Send the CSRF token in the header to authenticate the request
    },
    body: JSON.stringify(params),
  })
    .then((response) => handleCSRFErrors(response, url, params, post))
    .catch((error) => errorHandler(error, url, params));
};

/**
 * Sends a PUT request to the server
 *
 * @param {String} url subpath to the server
 * @param {Object} params Body of the request
 * @param {Function(Error, String, String)} errorHandler Given an error, the url and the params, this function should handle the error
 * @returns
 */
const put = async (url, params, errorHandler = defaultErrorHandling) => {
  return fetch(config.api_url + url, {
    method: "PUT",
    mode: "cors", // Cross-Origin Resource Sharing (CORS)
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + CookieManager.getCookie("token"), // Send the token in the header to authenticate the request
      "X-CSRFToken": await getCSRFToken(), // Send the CSRF token in the header to authenticate the request
    },
    body: JSON.stringify(params),
  })
    .then((response) => handleCSRFErrors(response, url, params, put))
    .catch((error) => errorHandler(error, url, params));
};

/**
 * Sends a PATCH request to the server
 *
 * @param {String} url subpath to the server
 * @param {Object} params Body of the request
 * @param {Function(Error, String, String)} errorHandler Given an error, the url and the params, this function should handle the error
 * @returns
 */
const patch = async (url, params, errorHandler = defaultErrorHandling) => {
  return fetch(config.api_url + url, {
    method: "PATCH",
    mode: "cors", // Cross-Origin Resource Sharing (CORS)
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + CookieManager.getCookie("token"), // Send the token in the header to authenticate the request
      "X-CSRFToken": await getCSRFToken(), // Send the CSRF token in the header to authenticate the request
    },
    body: JSON.stringify(params),
  })
    .then((response) => handleCSRFErrors(response, url, params, patch))
    .catch((error) => errorHandler(error, url, params));
};

/**
 * Sends a DELETE request to the server.
 *
 * @param {String} url subpath to the server
 * @param {Function(Error, String, Object)} errorHandler Given an error, the url and the params, this function should handle the error
 * @returns {Promise<JSON>} Resolves to the JSON response from the server
 */
const delet = async (url, errorHandler = defaultErrorHandling) => {
  return fetch(config.api_url + url, {
    method: "DELETE",
    mode: "cors", // Cross-Origin Resource Sharing (CORS)
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Token " + CookieManager.getCookie("token"), // Send the token in the header to authenticate the request
      "X-CSRFToken": await getCSRFToken(), // Send the CSRF token in the header to authenticate the request
    },
  }).catch((error) => errorHandler(error, url, {}));
};

export { get, post, put, patch, delet };
